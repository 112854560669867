import * as React from 'react';

import MainLayout from '../layouts/MainLayout';
import { Seo, Box, Text, Link } from '../components';

const ContactPage = () => {
  return (
    <MainLayout>
      <Seo title="Contact" />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="50vh"
        p={4}
        textAlign="center"
      >
        <Text variant="h3" color="primary" mb={4}>
          Spring Rain Pvt Ltd
        </Text>
        <Text variant="h5">Address</Text>
        <Text>
          7th Floor, Commercial Cove, House 56/C , Road 132, Dhaka 1212,
          Bangladesh
        </Text>
        <Text variant="h5">Email</Text>
        <Text>sapp.support@springrain.io</Text>
        <Text variant="h5">Website</Text>
        <Text>
          <Link to="https://springrainit.com">www.springrainit.com</Link>
        </Text>
      </Box>
    </MainLayout>
  );
};

export default ContactPage;
